import { gql } from '@apollo/client';

export const QUERY_SEARCH_PRODUCTS = gql`
    query ($first: Int, $last: Int, $filter: ProductFilterInput, $sortBy: ProductOrder, $after: String, $before: String) {
        products(first: $first, last: $last, before: $before, after: $after, filter: $filter, sortBy: $sortBy) {
            pageInfo {
                hasNextPage
                startCursor
                endCursor
                hasPreviousPage
            }
            totalCount
            edges {
                node {
                    id
                    name
                    slug
                    category {
                        slug
                        name
                    }
                    productType {
                        name
                        slug
                    }
                    defaultVariant {
                        id
                    }
                    location {
                        geography
                        address {
                            city
                        }
                    }
                    variants {
                        id
                    }
                    attributes {
                        attribute {
                            name
                            inputType
                        }
                        values {
                            name
                        }
                    }
                    images {
                        url (size: 500)
                        alt
                    }
                }
            }
        }

    }`;
