import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
    query {
        saleor {
            productTypes (first: 100) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const useStaticProductTypes = () => {
  const {
    saleor: {
      productTypes: { edges: productTypesRaw },
    },
  } = useStaticQuery(query);

  const productTypesOptions = productTypesRaw.map(({ node }) => ({
    label: node.name,
    value: node.id,
  }));
  const productTypeIds = productTypesRaw.map(({ node }) => node.id);
  return {
    productTypesOptions,
    productTypeIds,
  };
};
