import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Row, Space,
} from 'antd';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import css from './pagination.module.less';

export const Pagination = ({
  onPrevious,
  onNext,
  hasPrevious,
  hasNext,
  loading,
}) => (
  <Row justify="center" gutter={32} className={css.spacing}>
    <Col>
      <Space>
        <Button
          disabled={hasPrevious ? null : true}
          onClick={onPrevious}
          size="large"
          loading={loading}
          icon={<AiOutlineLeft />}
        />
        <Button
          disabled={hasNext ? null : true}
          onClick={onNext}
          loading={loading}
          size="large"
          icon={<AiOutlineRight />}
        />
      </Space>
    </Col>
  </Row>
);

Pagination.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  hasNext: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};
