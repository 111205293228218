import React, { useState } from 'react';
import { PageHeader, Radio, Result } from 'antd';
import { FcGlobe, FcPicture } from 'react-icons/all';
import { SearchResultsCards } from './layouts/search-results-cards';
import { SearchResultsMap } from './layouts/search-results-map';
import { Pagination } from '../../pagination/pagination';
import css from './search-results-container.module.less';
import { CenteredSection } from '../../layout/sections/centered/centered-section';

export const SearchResultsContainer = ({
  refetch,
  data,
  loading,
}) => {
  const [viewStyle, setViewStyle] = useState('cards');

  const onNext = () => {
    refetch({
      after: data?.products?.pageInfo.endCursor,
      first: 24,
      last: undefined,
      before: undefined,
    });
  };

  const onPrevious = () => {
    refetch({
      before: data?.products?.pageInfo.startCursor,
      after: undefined,
      first: undefined,
      last: 24,
    });
  };

  if (data?.products?.edges.length === 0) {
    return (
      <CenteredSection>
        <Result
          status="404"
          title="No results found"
          subTitle="Try clearing the filters"
        />
      </CenteredSection>
    );
  }

  let results = data?.products?.edges?.map(({ node }) => node);
  if (!results) {
    results = Array(24)
      .fill(0, 0, 24);
  }

  let resultsLayout;

  if (viewStyle === 'cards') {
    resultsLayout = <SearchResultsCards loading={loading} data={results} />;
  } else if (viewStyle === 'map') {
    resultsLayout = <SearchResultsMap loading={loading} data={results} />;
  }

  let title;
  if (loading) {
    title = 'Fetching...';
  } else if (data?.products.totalCount) {
    title = `${data?.products?.totalCount} Found`;
  }

  return (
    <PageHeader
      className={css.pageHeader}
      title={title}
      extra={(
        <Radio.Group value={viewStyle} onChange={(e) => setViewStyle(e.target.value)}>
          <Radio.Button value="cards">
            <FcPicture />
            {' '}
            Cards
          </Radio.Button>
          s
          <Radio.Button value="map">
            <FcGlobe />
            {' '}
            Map
          </Radio.Button>
        </Radio.Group>
      )}
    >
      {resultsLayout}
      <Pagination
        onNext={onNext}
        onPrevious={onPrevious}
        loading={loading}
        hasNext={data?.products?.pageInfo.hasNextPage || false}
        hasPrevious={data?.products?.pageInfo.hasPreviousPage || false}
      />
    </PageHeader>
  );
};
