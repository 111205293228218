import { Card, Col, Row } from 'antd';
import React from 'react';
import { CarouselCard } from '../../../cards/carousel-card/carousel-card';
import { Map } from '../../../map/map';
import css from './search-results-map.module.less';

export const SearchResultsMap = ({
  loading,
  data,
}) => (
  <Row gutter={[32, 32]}>
    <Col flex={1}>
      <Card className={css.mapCard}>
        <Map products={data} />
      </Card>
    </Col>
    <Col xs={24} md={12} lg={8} xxl={6}>
      <Row gutter={[32, 16]}>
        {data?.map((property, idx) => (
          <Col xs={24} key={property?.id || idx}>
            <CarouselCard
              loading={loading}
              variantId={property?.defaultVariant?.id || ''}
              variantCount={property?.variants?.length}
              attributes={property?.attributes}
              images={property?.images}
              productSlug={property?.slug}
              productType={property?.productType}
              productLocation={property?.location}
            />
          </Col>
        ))}
      </Row>
    </Col>
  </Row>
);
