export const getVarsFromForm = (formValues, separatedSlugs) => {
  const cleanedAttr = [];

  formValues?.attributes?.filter(
    ({ values }) => values.length > 0,
  )
    .forEach((attr) => cleanedAttr.push(attr));

  separatedSlugs?.forEach((slug) => {
    if (formValues?.[slug]?.length > 0) {
      cleanedAttr.push({
        slug,
        values: formValues?.[slug],
      });
    }
  });

  const filter = {
    search: formValues?.search,
    categories: formValues?.categories,
    productTypes: formValues?.productTypes,
    collections: formValues?.collections,
    attributes: cleanedAttr,
  };

  return {
    filter,
    first: 24,
    last: undefined,
    before: undefined,
    after: undefined,
  };
};
