import React from 'react';

import { Router } from '@reach/router';
import { BaseLayout } from '../components/layout/base/base-layout';
import { SEO } from '../components/layout/seo';
import { SearchContainer } from '../components/search/search-container';

const SearchPage = () => (
  <BaseLayout>
    <SEO title="Search" />
    <Router>
      <SearchContainer path="*" />
    </Router>
  </BaseLayout>
);

export default SearchPage;
