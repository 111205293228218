import { Select } from 'antd';
import React from 'react';

const buildSearchAttrObject = (attribute) => ({
  slug: attribute.slug,
  label: attribute.name,
  select: (
    <Select
      key={attribute.slug}
      showSearch
      placeholder={attribute.values.slice(0, 3)
        .map(
          ({ value }, idx) => (idx === 2 ? `${value}...` : `${value}, `),
        )}
      maxTagTextLength={15}
      maxTagCount={2}
      options={attribute.values}
      mode="multiple"
    />
  ),
});

export const getSearchFilterAttrs = (attributesOptions, currentProductTypeIds, separateSlugs) => {
  const separatedAttr = [];
  const attributes = [];
  attributesOptions.forEach((attribute) => {
    for (let i = 0; i < currentProductTypeIds.length; i += 1) {
      if (attribute.inProductTypes.includes(currentProductTypeIds[i])) {
        const attributeObj = buildSearchAttrObject(attribute);
        if (separateSlugs.includes(attribute.slug)) {
          separatedAttr.push(attributeObj);
        } else {
          attributes.push(attributeObj);
        }
        break;
      }
    }
  });
  return {
    separatedAttr,
    attributes,
  };
};
