import { useCallback } from 'react';

export const debounce = (fn, delay) => {
  let timer = null;
  return (...rest) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...rest);
    }, delay);
  };
};

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useDebounce = (fn, delay) => useCallback(debounce(fn, delay), []);
