import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const query = graphql`
    query {
        saleor {
            attributes (first: 100) {
                edges {
                    node {
                        slug
                        name
                        productTypes (first: 100) {
                            edges {
                                node {
                                    id
                                }
                            }
                        }
                        productVariantTypes (first: 100) {
                            edges {
                                node {
                                    id
                                }
                            }
                        }
                        values {
                            name
                            slug
                        }
                    }
                }
            }
        }
    }
`;

export const useStaticAttributes = () => {
  const {
    saleor: {
      attributes: { edges: attributesRaw },
    },
  } = useStaticQuery(query);

  return attributesRaw.map(
    ({ node }) => (
      {
        slug: node.slug,
        name: node.name,
        values: node.values.map((attr) => ({
          label: attr.name,
          value: attr.slug,
        })),
        inProductTypes: [
          ...node.productTypes.edges.map(({ node }) => node.id),
          ...node.productVariantTypes.edges.map(({ node }) => node.id),
        ],
      }
    ),
  );
};
