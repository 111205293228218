import {
  Button, Col, Collapse, Form, Input, Row, Select,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation } from '@reach/router';
import { MdClear } from 'react-icons/md';
import css from './search-filters-container.module.less';
import { PrivateComponent } from '../../auth/authorization/private-component';
import { useStaticCollections } from '../../../graphql/static-hooks/use-static-collections';
import { useStaticCategories } from '../../../graphql/static-hooks/use-static-categories';
import { useStaticAttributes } from '../../../graphql/static-hooks/use-static-attributes';
import { useStaticProductTypes } from '../../../graphql/static-hooks/use-static-product-types';
import { getSearchFilterAttrs } from '../utils/get-seach-filter-attrs';
import { getVarsFromForm } from '../utils/get-vars-from-form';
import { useDebounce } from '../utils/use-debounce';

const setUrl = (allValues) => {
  const params = {
    search: allValues?.search,
    location: allValues?.location,
    categories: allValues?.categories,
    productTypes: allValues?.productTypes,
    collections: allValues?.collections,
  };
  const qs = queryString.stringify(params, { arrayFormat: 'comma' });
  window.history.pushState({}, 'Search', `/search/?${qs}`);
};

const separatedSlugs = ['locations'];

export const SearchFilters = ({ refetch }) => {
  const [form] = Form.useForm();
  const { search } = useLocation();

  const collectionsOptions = useStaticCollections();
  const categoriesOptions = useStaticCategories();
  const attributesOptions = useStaticAttributes();
  const {
    productTypesOptions,
    productTypeIds,
  } = useStaticProductTypes();
  const [selectedProdTypeIds, setCurrentProductTypeIds] = useState(productTypeIds);

  const {
    separatedAttr,
    attributes,
  } = useMemo(
    () => getSearchFilterAttrs(attributesOptions,
      selectedProdTypeIds, separatedSlugs),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProdTypeIds],
  );

  const updateResults = (allValues) => {
    const vars = getVarsFromForm(allValues, separatedSlugs);
    refetch(vars);
  };

  useEffect(() => {
    if (search) {
      const parsedSearch = queryString.parse(search, { arrayFormat: 'comma' });
      form.setFieldsValue(parsedSearch);
      updateResults(form.getFieldsValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedRefetch = useDebounce(updateResults, 400);

  useEffect(() => {
    const newAttr = {
      attributes: attributes?.map((attr) => ({
        slug: attr.slug,
        values: [],
      })),
    };
    form.setFieldsValue(newAttr);
  }, [attributes, form]);

  const handleFormValuesChange = (changedValues, allValues) => {
    if ('productTypes' in changedValues) {
      setCurrentProductTypeIds(
        allValues?.productTypes?.length > 0
          ? allValues.productTypes : productTypesOptions.map(({ value }) => value),
      );
    }
    setUrl(allValues);
    debouncedRefetch(allValues);
  };

  const clearAll = (e) => {
    e.stopPropagation();
    form.resetFields();
    handleFormValuesChange({ productTypes: [] }, undefined);
  };

  return (
    <>
      <Form
        layout="vertical"
        name="search"
        size="large"
        onValuesChange={handleFormValuesChange}
        form={form}
        className={css.searchForm}
      >
        <Row gutter={16}>
          <PrivateComponent isStaff>
            <Col flex="1 1 150px">
              <Form.Item
                label="Search"
                name="search"
              >
                <Input placeholder="Search" />
              </Form.Item>
            </Col>
          </PrivateComponent>
          <PrivateComponent isStaff>
            <Col flex="1 1 150px">
              <Form.Item
                label="Categories"
                name="categories"
              >
                <Select
                  mode="multiple"
                  maxTagTextLength={15}
                  maxTagCount={3}
                  options={categoriesOptions}
                />
              </Form.Item>
            </Col>
          </PrivateComponent>
          <Col flex="1 1 150px">
            <Form.Item
              label="Search for"
              name="productTypes"
            >
              <Select
                placeholder="Venues, catering, accommodation..."
                mode="multiple"
                maxTagTextLength={15}
                maxTagCount={3}
                options={productTypesOptions}
              />
            </Form.Item>
          </Col>
          {separatedAttr.map((attr) => (
            <Col flex="1 1 150px" key={attr.slug}>
              <Form.Item
                name={attr.slug}
                label={attr.label}
              >
                {attr.select}
              </Form.Item>
            </Col>
          ))}
          <Col flex="1 1 150px">
            <Form.Item
              label="Collections"
              name="collections"
            >
              <Select
                mode="multiple"
                maxTagTextLength={15}
                maxTagCount={3}
                placeholder="Mobile World Congress - Barcelona"
                options={collectionsOptions}
              />
            </Form.Item>
          </Col>
          <Col flex="1 1 100%">
            <Collapse>
              <Collapse.Panel
                forceRender
                key="filter"
                header="Filter by attributes"
                extra={(
                  <Button onClick={clearAll} size="small" icon={<MdClear />} type="dashed">
                    Clear
                    Filters
                  </Button>
                )}
              >
                <Form.List name="attributes">
                  {(fields) => (attributes?.length === fields.length
                    && (
                      <Row gutter={16}>
                        {fields.map((field, index) => {
                          const thisField = attributes[index];
                          return (
                            <Col flex="1 1 25%" key={[field.fieldKey, 'values']}>
                              <Form.Item
                                name={[field.name, 'values']}
                                label={thisField.label}
                              >
                                {thisField.select}
                              </Form.Item>
                            </Col>
                          );
                        })}
                      </Row>
                    ))}
                </Form.List>
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
    </>
  );
};

SearchFilters.propTypes = {
  refetch: PropTypes.func.isRequired,
};
