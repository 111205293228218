import React from 'react';
import { Divider, Typography } from 'antd';
import { useQuery } from '@apollo/client';
import { SearchFilters } from './search-filters/search-filters';
import { QUERY_SEARCH_PRODUCTS } from '../../graphql/queries/queryProductsSearch';
import { SearchResultsContainer } from './search-results/search-results-container';

const { Title } = Typography;

export const SearchContainer = () => {
  const {
    data,
    loading,
    refetch,
    previousData,
  } = useQuery(QUERY_SEARCH_PRODUCTS, {
    variables: { first: 24 },
    notifyOnNetworkStatusChange: true,
  });

  return (
    <>
      <Title level={4} className="text-center">
        Discover the best event venues and trusted suppliers
      </Title>
      <Divider />
      <SearchFilters refetch={refetch} />
      <Divider />
      <SearchResultsContainer refetch={refetch} data={data || previousData} loading={loading} />
    </>
  );
};
