import { Col, Row } from 'antd';
import React from 'react';
import { CarouselCard } from '../../../cards/carousel-card/carousel-card';

export const SearchResultsCards = ({
  loading,
  data,
}) => (
  <Row gutter={[32, 48]}>
    {data?.map((property, idx) => (
      <Col xs={24} md={12} lg={8} xxl={6} key={property?.id || idx}>
        <CarouselCard
          loading={loading}
          variantId={property?.defaultVariant?.id || ''}
          variantCount={property?.variants?.length}
          attributes={property?.attributes}
          images={property?.images}
          productName={property?.name}
          productSlug={property?.slug}
          productType={property?.productType}
          productLocation={property?.location}
        />
      </Col>
    ))}
  </Row>
);
