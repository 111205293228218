import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

const query = graphql`
    query {
        saleor {
            collections (first: 100) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const useStaticCollections = () => {
  const {
    saleor:
      {
        collections:
          { edges: collectionsRaw },
      },
  } = useStaticQuery(query);

  return collectionsRaw.map(({ node }) => ({
    label: node.name,
    value: node.id,
  }));
};
